// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".deviceStatus{padding:2px 6px;border-radius:14px;margin-left:4px;position:relative;top:-2px;font-size:10px;color:#fff}.deviceStatus-online{background:#44af86}.deviceStatus-offline{background:#e03b50}.deviceStatus-warning{background:orange}.deviceStatus-unknown{background:#e03b50}.deviceStatus-open{background:orange}.deviceStatus-closed{background:#44af86}.deviceStatus-invited{background:#51c6e1}.deviceStatus-invited{background:#51c6e1}.deviceStatus-bigger{text-align:center;font-size:14px}.locationStatus{color:#fff}.locationStatus-online{background:#44af86}.locationStatus-offline{background:#737d96}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
