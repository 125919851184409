// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".raderConfigImg{height:400px;margin-bottom:24px}.legend{line-height:2;font-weight:bold}.legend .square{float:left;width:24px;height:24px;border-radius:4px;border:1px solid;margin:5px 12px 12px 5px}.legend .greenBg{background-color:#44af86;border-color:#44af86}.legend .blueBg{background-color:#59cbe8;border-color:#59cbe8}.legend .whiteBg{background-color:#fff;border:3px solid #000}.disabled{pointer-events:none;opacity:.4}.radarSetting .icon{font-size:20px;margin-right:4px}.radarSetting .icon-success{color:#44af86}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
