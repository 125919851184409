// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoticeList{max-height:400px;overflow:auto}.NoticeList::-webkit-scrollbar{display:none}.NoticeList .item{padding-right:24px;padding-left:24px;overflow:hidden;cursor:pointer;transition:all .3s}.NoticeList .item .meta{width:100%}.NoticeList .item .avatar{margin-top:4px;background:#fff}.NoticeList .item .iconElement{font-size:32px}.NoticeList .item.read{opacity:.4}.NoticeList .item:last-child{border-bottom:0}.NoticeList .item:hover{background:#eefafd}.NoticeList .item .title{margin-bottom:8px;font-weight:normal}.NoticeList .item .description{font-size:12px;line-height:1.5715}.NoticeList .item .datetime{margin-top:4px;font-size:12px;line-height:1.5715}.NoticeList .item .extra{float:right;margin-top:-1.5px;margin-right:0;color:#b3bbce;font-weight:normal}.NoticeList .loadMore{padding:8px 0;color:#59cbe8;text-align:center;cursor:pointer}.NoticeList .loadMore.loadedAll{color:rgba(0,0,0,.25);cursor:unset}.NoticeListNotFound{padding:73px 0 88px;color:#b3bbce;text-align:center}.NoticeListNotFound img{display:inline-block;height:76px;margin-bottom:16px}.NoticeListBottomBar{height:46px;color:fade(#000, 65%);line-height:46px;text-align:center;border-top:1px solid hsv(0, 0, 91%);border-radius:0 0 4px 4px;transition:all .3s}.NoticeListBottomBar div{display:inline-block;width:50%;cursor:pointer;transition:all .3s;user-select:none}.NoticeListBottomBar div:only-child{width:100%}.NoticeListBottomBar div:not(:only-child):last-child{border-left:1px solid hsv(0, 0, 91%)}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
