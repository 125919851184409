// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".steps-content{min-height:256px}.steps-action{margin-top:24px;min-height:30px}.floatRight{float:right}.ant-steps{margin-left:-15px}.roundLogoContainer{background:#2f6d7c;margin-bottom:30px;width:200px;height:200px;text-align:center;border:50px solid #2f6d7c;border-radius:50%;cursor:pointer}@media(max-width: 480px){.roundLogoContainer{width:100px;height:100px;border:20px solid #2f6d7c;margin-bottom:20px}}.claimImg{width:100px;height:100px}@media(max-width: 480px){.claimImg{width:60px;height:60px}}.claimRadarImg{width:80px;height:100px}@media(max-width: 480px){.claimRadarImg{width:45px;height:60px}}.claimText{color:#2f6d7c;font-size:18px;font-weight:400}@media(max-width: 480px){.claimText{font-size:14px}}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
