import { Avatar, List } from 'antd'
import React from 'react'
import classNames from 'classnames'
import type { NoticeIconData } from './index'
import './NoticeList.scss'

export type NoticeIconTabProps = {
    count?: number;
    name?: string;
    showClear?: boolean;
    showViewMore?: boolean;
    style?: React.CSSProperties;
    title: string;
    tabKey: string;
    data?: NoticeIconData[];
    onClick?: (item: NoticeIconData) => void;
    onClear?: () => void;
    emptyText?: string;
    clearText?: string;
    viewMoreText?: string;
    list: NoticeIconData[];
    onViewMore?: (e: any) => void;
}

const NoticeList: React.SFC<NoticeIconTabProps> = ({
    data = [],
    onClick,
    onClear,
    title,
    onViewMore,
    emptyText,
    showClear = true,
    clearText,
    viewMoreText,
    showViewMore = false,
}) => {
    if (!data || data.length === 0) {
        return (
            <div className="NoticeListNotFound">
                <img
                    src="https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg"
                    alt="not found"
                />
                <div>{emptyText}</div>
            </div>
        )
    }
    return (
        <div>
            <List
                className="NoticeList"
                dataSource={data}
                renderItem={(item, i) => {
                    const itemCls = classNames('item', 'item.read'
                    )
                    // eslint-disable-next-line no-nested-ternary
                    const leftIcon = item.avatar ? (
                        typeof item.avatar === 'string' ? (
                            <Avatar className="avatar" src={item.avatar} />
                        ) : (
                            <span className='iconElement'>{item.avatar}</span>
                        )
                    ) : null

                    return (
                        <List.Item
                            className={itemCls}
                            key={item.key || i}
                            onClick={() => {
                                onClick?.(item)
                            }}
                        >
                            <List.Item.Meta
                                className='meta'
                                avatar={leftIcon}
                                title={
                                    <div className='title'>
                                        {item.title}
                                        <div className='extra'>{item.extra}</div>
                                    </div>
                                }
                                description={
                                    <div>
                                        <div className={'description'}>{item.description}</div>
                                        <div className={'datetime'}>{item.datetime}</div>
                                    </div>
                                }
                            />
                        </List.Item>
                    )
                }}
            />
            <div className='NoticeListBottomBar'>
                {showClear ? (
                    <div onClick={onClear}>
                        {clearText} {title}
                    </div>
                ) : null}
                {showViewMore ? (
                    <div
                        onClick={(e) => {
                            if (onViewMore) {
                                onViewMore(e)
                            }
                        }}
                    >
                        {viewMoreText}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default NoticeList
