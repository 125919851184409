// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".baseView{display:flex;padding-top:12px}.baseView .left{max-width:448px;min-width:224px}.baseView .right{flex:1;padding-left:104px}@media screen and (max-width: 1200px){.baseView{flex-direction:column-reverse}.baseView .right{padding:20px;display:flex;flex-direction:column;align-items:center;max-width:448px}.baseView .right .avatar_title{display:none}}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
