// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrgHeader{background:#fff}.OrgHeader-Title{font-size:20px;font-weight:700;margin-left:20px}.OrgHeader-Icon{font-size:50px;vertical-align:middle}.OrgHeader .ant-select{margin-left:20px;width:250px}.OrgHeader .mobileOrgSelection{line-height:2}.OrgHeader .mobileOrgSelection .ant-select{width:calc(100% - 20px)}.OrgHeader .mobileOrgSelection span{font-size:16px}@media(max-width: 768px){.OrgHeader{padding:0 20px;background:#59cbe8;color:#fff}}.OrgBody{min-height:calc(100vh - 160px)}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
