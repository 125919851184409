import { DatePicker, Form, Input, Radio, Space } from 'antd'
import { Fragment, useState } from 'react'

const ContractOptions = (props) => {
    const {periodRequire, isContract} = props
    const [contractValue, setContractValue] = useState(12)

    return <Space direction="vertical" className="large-margin-bottom">
        <Form.Item name="contract" label="Period" rules={[{required: periodRequire, message: 'Please select contract period'}]} labelCol={{span: 6}} wrapperCol={{span: 18}}>
            <Radio.Group onChange={e => setContractValue(e.target.value)}>
                <Radio value={1200}>Never end (100yrs)</Radio>
                <Radio value={12}>12 months</Radio>
                <Radio value={24}>24 months</Radio>
                <Radio value={36}>36 months</Radio>
                <Radio value="custom">Custom</Radio>
            </Radio.Group>
        </Form.Item>

        {contractValue === 'custom' && <Form.Item name="customDate" rules={[ {
            required: contractValue === 'custom',
            message: 'Please select the end date',
        },]}>
            <DatePicker autoFocus/>
        </Form.Item>
        }
        {isContract&&<Fragment>
            <Form.Item name="contract_email" label="Requested by (email)" rules={[{
                type: 'email', message: 'The input is not valid E-mail!',
            }]}>
                <Input />
            </Form.Item>

            <Form.Item name="contract_company" label="Requested company NDIS/Home Care Provider" >
                <Input />
            </Form.Item>

            <Form.Item name="contract_finance_ref" label="SOFIHUB Finance Reference ID" >
                <Input />
            </Form.Item>

            <Form.Item name="admin_notes" label="Notes">
                <Input.TextArea />
            </Form.Item>
        </Fragment>}
    </Space>
}

ContractOptions.defalutProps = {
    isContract: true,
    periodRequire: false
}

export default ContractOptions
