// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".right{width:-webkit-fill-available;float:right;height:100%;overflow:hidden}.right .ant-menu-horizontal{line-height:46px}.right .account .avatar{margin-right:8px;color:rgba(0,0,0,.65);background:rgba(255,255,255,.85)}.menu :global(.anticon){margin-right:8px}.menu :global(.ant-dropdown-menu-item){min-width:160px}@media only screen and (max-width: 768px){.name{display:none}.avatar{margin-right:0}}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
