// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".status{border-top-left-radius:5px;border-bottom-left-radius:5px;display:flex;justify-content:center;align-items:center}.mobile{height:50px !important}.status-text{color:#fff;margin:0 auto;text-align:center;max-height:180px}.margin-right{margin-right:10px}.bold{font-weight:700}.thin{font-weight:300;font-size:smaller;color:#b3bbce}.right-container{width:100%;padding-left:16px;padding-top:10px;background:#fff}@media(max-width: 400px){.right-container{font-size:14px;padding-left:4px}}.green{background:#44af86}.red{background:#e03b50}.blue{background:#51c6e1}.arrow-group{margin-top:10px;display:flex;justify-content:space-between;width:100%}.short-input .ant-calendar-picker-input{max-width:120px}.fontRed{color:#e03b50}.fontGreen{color:#44af86}.beaconAlertCard{border-top-right-radius:0}.beaconAlertCard .borderTop{border-top:2px solid}.beaconAlertCard .borderRed{border-color:#e03b50}.beaconAlertCard .borderGreen{border-color:#51c6e1}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
