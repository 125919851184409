// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".infoIconStep{font-size:36px;color:#59cbe8}.noBottomMargin .ant-legacy-form-item{margin-bottom:0}.welcomeCard{margin-bottom:30px}.welcomeCard .ant-card-head-title{text-align:center;font-size:16px}.messageIcon{position:relative;font-size:76px;color:#9a9a9a;top:-250px;left:5px}@media(max-width: 480px){.messageIcon{font-size:50px;top:-180px}}.hubImg{box-shadow:0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);margin-bottom:30px;width:76px;height:200px}@media(max-width: 480px){.hubImg{width:38px;height:100px;margin-bottom:20px}}.reminderImg{box-shadow:0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);margin-top:100px;margin-bottom:30px;width:76px;height:200px}@media(max-width: 480px){.reminderImg{width:57px;height:150px;margin-top:50px;margin-bottom:20px;margin-left:50px}}.sensorImg{width:76px;height:200px}@media(max-width: 480px){.sensorImg{width:57px;height:150px;margin-top:50px;margin-bottom:20px;margin-left:50px}}.reminders_ul{padding-left:20px;padding-right:30px;column-count:2;list-style-type:\"-  \"}@media(max-width: 480px){.reminders_ul{columns:1}}@media(min-width: 900px){.hubWelcomeSteps .ant-steps-item{max-width:110px}.hubWelcomeSteps .ant-steps-item-content{width:110px}}.hubWelcomeSteps .ant-steps-horizontal-icon{margin-left:40px !important}.welcomeModal .ant-modal-body{padding:0}.welcomeModal .ant-card-head{padding:0}.welcomeModal .ant-card-head-title{background:#44af86;color:#fff;text-align:center;font-size:24px}@media(max-width: 480px){.welcomeModal .ant-card-head-title{font-size:16px}}.welcomeModal .deviceIconCol{height:auto;width:120px;margin:auto}.welcomeModal .hub{width:80px;height:180px}.welcomeModal .beacon{width:100px;height:125px}.welcomeModal .radar{width:60px;height:auto;padding-bottom:8px}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
