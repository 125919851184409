// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".main{width:100%;height:100%;background-color:#fff;display:flex;padding-top:16px;padding-bottom:16px;overflow:auto}.main .leftmenu{width:320px;border-right:1px solid hsv(0, 0, 91%)}.main .leftmenu :global .ant-menu-inline{border:none}.main .leftmenu :global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{font-weight:bold}.main .right{flex:1;padding-left:40px;padding-right:40px;padding-top:8px;padding-bottom:8px}.main :global .ant-list-split .ant-list-item:last-child{border-bottom:1px solid #e8e8e8}.main :global .ant-list-item{padding-top:14px;padding-bottom:14px}@media screen and (max-width: 768px){.main{flex-direction:column}.main .leftmenu{width:100%;border:none}.main .right{padding:40px}}.dangerTitle{color:#e03b50}.loadingStatus{font-size:20px}.loadingStatus .loadingStatusIcon{margin-right:6px;font-size:22px}.loadingStatus .success{color:#44af86}.loadingStatus .active{color:#59cbe8}.loadingStatus .error{color:#e03b50}.dynamic-delete-button{cursor:pointer;position:relative;top:4px;font-size:24px;color:#999;transition:all .3s}.dynamic-delete-button:hover{color:#777}.dynamic-delete-button[disabled]{cursor:not-allowed;opacity:.5}.my-editor{border:1px solid #d1d1d1;border-radius:5px}.my-editor .bf-content{height:250px}.ant-list-header{font-weight:bold}.vertical-form-label-nowrap .ant-form-item-label>label{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
