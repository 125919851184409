// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bodyTitle{height:80px}.bodyTitle .title{color:#000;font-size:22px}.bodyTitle .anticon{font-size:42px;padding-right:6px}.good{color:#44af86}.good a{color:#44af86;text-decoration:underline}.exclamation{color:#e03b50}.exclamation a{color:#e03b50;text-decoration:underline}.glanceContainer{padding:30px;text-align:center;height:100%}.glanceContainer.urgent{background:#fdedef}.glanceContainer.normal{background:#eef7f3;color:#44af86}.bodySection{max-width:380px;text-align:left;font-size:18px}.bodySection .anticon{padding-right:6px}.noIconMargin{margin-left:28px}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
