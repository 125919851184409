// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderDropdownContainer{background-color:#fff;border-radius:4px;box-shadow:0 2px 8px rgba(0,0,0,.15)}.HeaderDropdownContainer .ant-dropdown-menu{padding:0}@media screen and (max-width: 480px){.HeaderDropdownContainer{width:100% !important}.container>*{border-radius:0 !important}}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
