// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".createReminderModal-logo{font-size:48px}.createReminderModal-status{color:#44af86;display:flex;justify-content:center;flex-direction:column;font-size:24px;margin-bottom:20px;text-transform:capitalize;font-weight:700}.createReminderModal .ant-modal-footer{border-top:0px}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
