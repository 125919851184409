// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".radarStatusContainer{margin:30px;position:relative;font-weight:500;vertical-align:middle;white-space:pre}@media all and (max-width: 768px){.radarStatusContainer{margin:16px}}.radarStatusContainer .fontHeading{text-overflow:ellipsis;overflow:hidden;font-size:20px;font-weight:600}.radarStatusContainer .statusRightContainer{margin:6px 12px}.radarStatusContainer .avatarContainer{height:100%;border-top-left-radius:4px;border-bottom-left-radius:4px}.radarStatusContainer .avatarContainer img{font-size:100px;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;width:66px}.radarStatusCard{color:#fff;font-size:14px}@media all and (max-width: 768px){.radarStatusCard{font-size:12px}}.radarStatusCard .fontHeading{font-weight:600}.radarStatusCard .cloudIcon{line-height:1;font-weight:600;font-size:70px;text-align:center}.radarStatusCard .personIcon{line-height:1;font-weight:600;font-size:52px;text-align:center}.radarStatusCard .alignBottom{font-size:16px;width:100%;margin-top:auto}.radarStatusCard .ant-card-body{padding:0 12px;height:140px;border-radius:4px;display:flex;flex-direction:column}.radarMapContainer{margin:30px}@media all and (max-width: 576px){.radarMapContainer{height:750px}}.disabledCard{cursor:not-allowed;opacity:.4}.offlineStrip{background-image:repeating-linear-gradient(-45deg, #FFA500, #FFA500 2px, black 3px, black 5px)}", ""]);
// Exports
exports.locals = {
	"gradientStart": "#4a148c",
	"gradientEnd": "#03a9f4",
	"red": "#e03b50",
	"orange": "orange",
	"blue": "#59cbe8",
	"lightText": "#b3bbce",
	"green": "#44af86",
	"grey": "#737d96",
	"brown": "#af8644",
	"darkText": "#384954"
};
module.exports = exports;
